.MuiStepper-root {
  padding-top: 10px !important;
  margin: left -109px !important;
  margin-bottom: 6px;
}
.startbtn {
  margin-left: 10%;
  align-items: center;
}
.nextbtn {
  margin-left: 900px;
}
.MuiGrid-spacing-xs-3 {
  padding-left: 138px !important;
}
.centered {
  width: 90%;
  background-color: red;
}
.bckNxt {
  background-color: white;
  margin-top: 2%;
  margin-bottom: 1%;
  padding-top: 1%;
  padding-left: 8%;
  padding-right: 8%;
}

.sample-image {
  padding: 20px;
}

.sample-btn {
  background: #17664e;
  color: #fff;
  padding: 6px 16px;
  border-radius: 4px;
  cursor: pointer;
  outline: unset;
  border: none;
  float: right;
}
.sample-image img {
  /* height: 70vh; */
  width: 100%;
  object-fit: contain;
  object-position: center;
  margin: 15px 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .bckNxt {
    /* margin-top: -8%; */
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.fullHam {
  padding: 0;
}
