.testimonial {
  // font-family: "Livvic", sans-serif;
  font-family: "Open Sans", sans-serif;
  position: relative;
  background: #d2e6e4;
  margin-bottom: -10px;
  position: relative;
  min-height: 510px;
  .content-block {
    margin: 0 auto;
    width: 80%;
  }
}
.testimonial h1 {
  text-align: center;
  color: #17664e;
  font-size: 30px;
  font-weight: 700px;
  margin-bottom: 20px;
}

.swiper-container {
  margin: 10px 60px 10px 60px;
}

.swiper-slide {
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.content {
  background-color: #ffff;
  border-radius: 20px;
  padding: 30px 30px 30px;
  position: relative;
}
.content p,
.content h1 {
  text-align: center;
  font-size: 16px;
}

.testimonial h1 {
  margin-bottom: 53px;
}

.userDetail h5,
.userDetail p {
  line-height: 0;
}

.userDetail {
  margin-top: 7px !important;
}

.content h1 {
  margin-bottom: 18px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: contain;
}

.triangle {
  border: 10px solid transparent;
  width: 0;
  border-bottom: 10px solid white;
  transform: rotate(180deg);
  position: absolute;
  bottom: -30;
  left: 50%;
}

@media only screen and (max-width: 600px) {
  .testimonial {
    background: #d2e6e4;
  }
}
@media only screen and (max-width: 960px) {
  .testimonial {
    background: #d2e6e4;
  }
}

@media only screen and (max-width: 1280px) {
  .testimonial {
    background: #d2e6e4;
    //not-height: 476px;
  }
}
@media only screen and (max-width: 600px) {
  .testimonial h1 {
    font-size: 24px;
    line-height: 25.1px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: -4px;
    left: 0;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .testimonial {
    padding: 20px 15px;
    .content-block {
      width: 100%;
    }
    .swiper-container {
      margin: 0;
    }
    .content {
      padding: 15px;
      width: 80vh;
    }
    .triangle {
      border: 10px solid transparent;
      width: 0;
      border-bottom: 10px solid white;
      transform: rotate(180deg);
    }
  }
}

// @media (max-width: 992px) {
//   .testimonial {
//     min-height: 560px;
//   }
// }
