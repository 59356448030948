@import "bootstrap/dist/css/bootstrap.min.css";
.blog-section {
  // font-family: "Livvic,sans-serrif";
  font-family: "Open Sans", sans-serif;

  .blog-banner {
    height: 200px;
    width: 100%;
    background-color: #7cdcd0cc;
    position: relative;
    overflow: hidden;
    color: #006f60;
    margin-top: 80px;
    z-index: 1;
    .breadcrumb-section {
      // display: flex;
      // align-items: center;
      height: 100%;
      position: absolute;
      width: 100%;
      flex-direction: column;
      top: 50px;
      z-index: 1;
      .breadcrumb-title {
        color: #006f60;
        width: 100%;
      }
      .breadcrumb-subtitle {
        color: #10a390;
        width: 100%;
        a {
          text-decoration: unset;
          color: #006f60;
        }
      }
    }

    img {
      bottom: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      opacity: 0.13;
      position: absolute;
      width: 100%;
    }
  }

  .section-card {
    box-shadow: 0 10px 24px -7px rgb(0 0 0 / 10%);
    margin-bottom: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    min-height: 475px;

    img {
      width: 100%;
      height: 230px;
      object-fit: cover;
      border-radius: 10px;
    }

    .blog-content {
      margin-top: 10px;

      .d-space-between {
        display: flex;
        justify-content: space-between;
        margin: 4px 0;
      }
      .d-flex {
        align-items: center;
        svg {
          font-size: 15px;
          margin: 3px 0;
          color: #454343a3;
        }
        p {
          margin-left: 3px;
          font-size: 13px;
          margin-bottom: 0;
          color: #454343a3;
        }
      }
      .title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 4px;
        min-height: 35px;

        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
        display: -webkit-box;

        a {
          text-decoration: none;
          color: unset;
          font-weight: 600;
        }
      }

      .description {
        font-size: 16px;
        margin: 6px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        height: 100px;
      }

      a {
        font-size: 16px;
        color: #17664e;
      }
    }
  }
}
