.makeStyles-instructions-3 {
  margin-left: 138px !important;
}
.hr {
  margin-top: 20px;
  border: none;
  border: 1.5px dotted black;
  background-color: #fff;
  height: 300px;
  width: 70%;
  align-content: center;
  margin-left: 60px;
  margin-bottom: 80px;
}
.center {
  padding-top: 90px;
}
.typCenter {
  padding-left: 17%;
  padding-top: 3%;
  padding-bottom: 2%;
}
.uploadPhoto {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
}
.banner img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.uploadPhoto .makeStyles-imageContainer-32 {
  margin: auto;
}
