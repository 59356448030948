


.consult-form-section{
        background-color: "white";
        border-radius: "5px";


        .select2-container{
            width: 85% !important;
        }
    .select2-selection{
      border-color: #ced4da !important;
      margin-top: -5px;
    //   border-radius: 0.375rem;
      min-height: 46px;
      padding: 10px !important;
      width: 100%;
  
      .select2-selection__arrow{
        top: 6px;
      }
    }

    .select2-search__field{
        padding: 8px !important;
    }
  
  }