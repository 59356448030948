.single-blog-section {
  margin-top: 80px;
  .wrapper {
    margin-top: 20px;
  }
  .section-card {
    margin-top: 20px;
    // box-shadow: 0 10px 24px -7px rgb(0 0 0 / 10%);
    // margin-bottom: 20px;
    // background-color: #fff;
    // padding: 20px;
    // border-radius: 5px;
    // transition: all 0.3s ease-in-out;
    .breadcrumb-subtitle {
      color: #a8a8a8;
      width: 100%;
      font-size: 15px;
      a {
        text-decoration: unset;
        color: #676565;
      }
    }
    h1.main-title {
      font-size: 32px;
      font-weight: 500;
      margin: 10px 0 10px 0;
      line-height: 56px;
    }
    .title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 4px;
    }
    img {
      width: 100%;
      // height: 350px;
      object-fit: cover;
      border-radius: 10px;
      margin-bottom: 30px;
    }

    .blog-content {
      margin-top: 10px;
      .date-section {
        color: #717677;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 1px;
        display: flex;

        .date-detail {
          font-size: 15px;
          margin-left: 6px;
        }
        .date-content {
          display: flex;
          margin-right: 30px;
        }
        .author-content {
          display: flex;
        }
      }

      .d-space-between {
        display: flex;
        justify-content: space-between;
        margin: 4px 0;
      }
      .d-flex {
        align-items: center;
        svg {
          font-size: 15px;
          margin: 3px 0;
          color: #717677;
        }
        p {
          margin-left: 3px;
          font-size: 13px;
          margin-bottom: 0;
          color: #717677;
        }
      }

      .description {
        font-size: 16px;
        margin: 6px 0;
        line-height: 30px;
      }
    }
  }

  .related-blog-section {
    background: #f0f8ff57;
    padding: 15px;

    .main-title {
      font-size: 20px;
      margin-bottom: 3px;
    }
    .title-border {
      border: 1px solid #196d5f;
      padding: 1px;
      width: 70px;
      background: #196d5f;
      margin-bottom: 10px;
    }
    .blog-content {
      margin-bottom: 15px;
      img {
        height: 70px;
        width: 80px;
        margin-right: 12px;
        border-radius: 5px;
        object-fit: cover;
      }

      .title {
        font-size: 15px;
        margin-bottom: 2px;
      }

      .description {
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 0;
      }

      a {
        font-size: 15px;
        color: #212529;
        text-decoration: unset;
      }
      .author-name {
        margin-bottom: 0;
        font-size: 13px;
        color: #717677;
      }
    }
  }
}

@media (max-width: 992px) {
  .single-blog-section {
    .main-title {
      line-height: 40px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .single-blog-section {
    .section-card {
      .breadcrumb-subtitle {
        font-size: 13px;
      }

      h1.main-title {
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
        margin: 10px 0 10px 0;
      }

      .blog-content{
        .date-section {
  
          .date-detail {
            font-size: 13px;
            margin-left: 4px;
          }
        }
        .description {
          text-align: justify;
        }
  
      } 
    }
  }
}