


.insurance-form-section{

  .select2-selection{
    border-color: #ced4da;
    margin-top: 1px;
    border-radius: 0.375rem;
    min-height: 38px;
    padding: 5px;

    .select2-selection__arrow{
      top: 6px;
    }
  }

}